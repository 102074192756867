@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  box-size: 'border-box';
  max-width: 100vw;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
